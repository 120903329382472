import React from "react";
import Swiper from "swiper";

export const Gallery = ({ images }) => {
  React.useLayoutEffect(() => {
    new Swiper(".swiper-container", {
      slidesPerView: "auto",
      spaceBetween: 10,
      freeMode: true,
      grabCursor: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  React.useLayoutEffect(() => {
    const updateDimensions = () => {
      // Select the elements using query selectors
      const judgesInnerSection = document.querySelector('#judges > .section__inner');
      const galleryInnerSection = document.querySelector('#gallery > .section__inner');
      const galleryContainer = document.querySelector('#gallery > .section__inner > div.gallery-container');
  
      // Ensure all elements exist
      if (judgesInnerSection && galleryInnerSection && galleryContainer) {
        // Assign the margin-left to the target element
        galleryInnerSection.style.marginLeft = window.getComputedStyle(judgesInnerSection).marginLeft;
        galleryInnerSection.style.marginRight = '0px';
  
        // Get the computed styles of both elements
        const judgesInnerSectionMarginRight = parseFloat(window.getComputedStyle(judgesInnerSection).marginRight);
        const galleryInnerSectionWidth = parseFloat(window.getComputedStyle(galleryInnerSection).width);
  
        // Calculate the new width
        const newWidth = galleryInnerSectionWidth + judgesInnerSectionMarginRight;
  
        // Apply the new width to the target element
        galleryInnerSection.style.width = `${newWidth}px`;
        galleryContainer.style.width = `${newWidth}px`;
      }
    };
  
    updateDimensions();
  
    window.addEventListener('resize', updateDimensions);
  
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className="gallery-container">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {images.map((image, index) => (
              <div
                key={index}
                className="swiper-slide"
                style={{ width: "490px", height: "275px" }}
              >
                <img
                  src={image}
                  alt={`gallery-img-${index}`}
                  className="gallery-image"
                />
              </div>
            ))}
        </div>
      </div>

      <div className="gallery-controls">
        <div className="swiper-button-prev">←</div>
        <div className="swiper-button-next">→</div>
      </div>
    </div>
  );
};
