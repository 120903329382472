import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Slider from "react-slick";
import {
  Image,
  Button,
} from "../../";

export const Vertical = ({ articles, speed, slidesToShow, slidesToScroll, fade, padding, label, settings }) => {
  const defaultSettings = {
    className: "carousel__vertical",
    swipeToSlide: true,
    dots: true,
    infinite: true,
    fade: fade || false,
    speed: speed || 500,
    autoplaySpeed: 5000,
    slidesToShow: slidesToShow || 1,
    slidesToScroll: slidesToScroll || 1,
    centerMode: true,
    centerPadding: padding || "0",
    autoplay: true,
    arrows: false,
    pauseOnHover: true,
    dotsClass: "large__dots inverse",
    customPaging: function(i) {
      return (
        <a>&nbsp;</a>
      );
    },
  };

  // If no Carousel items, don't render a carousel component
  if (articles.length <= 0) return null;
  const sliderRef = useRef(null);

  const nextSlide = () => {
    sliderRef.current.slickNext()
  }

  const prevSlide = () => {
    sliderRef.current.slickPrev()
  }

  return (
    <Fragment>
      <Slider ref={sliderRef} {...Object.assign({}, defaultSettings, settings)}>
        {articles.map((article, i) => (
          <div
            key={`carousel-item-${i}`}
            className="carousel__item"
          >
            <div className="carousel__itemContents">
              <div className="carousel__itemContents__left">
                  <div className="carousel__item__title">{article.title}</div>
                  <div
                    className="carousel__item__deck"
                    dangerouslySetInnerHTML={{__html: article.deck}}
                  />
                  {get(article, 'url') && (
                    <div className="carousel__item__button">
                      <a target="_blank" href={article.url}>
                        <Button rounded label={article.buttonText || 'Read More'} bgColor="#00C3FF" labelHoverColor="#ffffff" />
                      </a>
                    </div>
                  )}
              </div>
              <div className="carousel__itemContents__right">
                <Image image={article.image} />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

Vertical.propTypes = {
  articles: PropTypes.array.isRequired,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  fade: PropTypes.bool,
  label: PropTypes.string
};

Vertical.defaultProps = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
