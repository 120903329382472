import React from "react";
import PropTypes from "prop-types";
import { Image, Eyebrow } from "../";
import { slugify } from '../../../../../utils/string_util';

export const ArticleList = ({ articles, name, image, activeIndex, noLogo }) => {
  const scrollToSection = (title) => {
    const id = slugify(title);
    const section = document.getElementById(id);

    window.scrollTo({
      top: section.getBoundingClientRect().top + window.scrollY - 90, // 90px for a full expanded fc top nav
      behavior: 'smooth'
    })
  }

  return (
    <div className={`articleList__container ${name ? `articleList__${name}` : ''}`}>
      <div className="articleList__inner" id="articleList__inner">
        {!noLogo &&
          <div className="articleList__header">
            <div className="articleList__header__logo" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              {image && (
                <Image image={image} />
              )}
            </div>
          </div>
        }
        {articles.map((article, index) => (
          <div key={`articleList__item-${index}`} id={`articleList__item-${index}`} className={`articleList__item ${activeIndex == index ? 'active' : ''}`}>
            <Eyebrow>{article.eyebrow}</Eyebrow>
            <div className="articleList__item__title">
              {article.url ? (
                <a
                  href={article.url}
                  {...(article?.openInNewTab ? { target: "_blank" } : {})}
                >
                  {article.sectionTitle}
                </a>
              ) : (
                <a onClick={() => scrollToSection(article.sectionTitle)}>{article.sectionTitle}</a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ArticleList.propTypes = {
  articles: PropTypes.array,
  name: PropTypes.string,
  image: PropTypes.string
};

ArticleList.defaultProps = {
  articles: [],
};
