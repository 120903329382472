import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Slider from "react-slick";
import {
  Image,
  Button,
} from "../../";

export const FullWidth = ({ articles, speed, slidesToShow, slidesToScroll, fade, padding, label, settings, section = "" }) => {
  const defaultSettings = {
    className: "carousel__fullWidth",
    swipeToSlide: true,
    dots: true,
    infinite: true,
    fade: fade || false,
    speed: speed || 500,
    autoplaySpeed: 5000,
    slidesToShow: slidesToShow || 1,
    slidesToScroll: slidesToScroll || 1,
    centerMode: true,
    centerPadding: padding || "0",
    autoplay: true,
    arrows: false,
    pauseOnHover: true,
    dotsClass: "large__dots inverse",
    customPaging: function(i) {
      return (
        <a>&nbsp;</a>
      );
    },
  };

  // If no Carousel items, don't render a carousel component
  if (articles.length <= 0) return null;
  const sliderRef = useRef(null);

  const nextSlide = () => {
    sliderRef.current.slickNext()
  }

  const prevSlide = () => {
    sliderRef.current.slickPrev()
  }

  const getBackground = (article) => {
    const isGradient = get(article, 'gradient', '');
    const background = get(article, 'background', '');
    return isGradient
      ? `linear-gradient(90deg, rgba(255,255,255,1) 50%, #${background} 100%)`
      : `linear-gradient(90deg, #${background} 100%, #${background} 100%)`
  }

  return (
    <Fragment>
      <Slider ref={sliderRef} {...Object.assign({}, defaultSettings, settings)}>
        {articles.map((article, i) => (
          <div
            key={`carousel-item-${i}`}
            className={`carousel__item carousel__${section}`}
          >
            <div className="carousel__itemContents" style={{background: getBackground(article)}}>
              <div className="carousel__itemContents__left">
                <Image image={get(article, 'image', '')} />
              </div>
              <div className="carousel__itemContents__right">
                <div className="carousel__item__franchise_title"> {get(article, 'header', '')} </div>
                <div className="carousel__item__title">{get(article,'title', '')}</div>
                <div
                  className="carousel__item__deck"
                  dangerouslySetInnerHTML={{__html: get(article,'deck', '')}}
                />
                {get(article, 'url') && (
                  <div className="carousel__item__button">
                    <a target="_blank" href={get(article, 'url', '')}>
                      <Button rounded label="READ MORE" bgColor="#00C3FF" hoverColor="#00C3FF"/>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

FullWidth.propTypes = {
  articles: PropTypes.array.isRequired,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  fade: PropTypes.bool,
  label: PropTypes.string
};

FullWidth.defaultProps = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
