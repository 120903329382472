import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import {
  Button
} from '..';

export const Supplemental = (supplementalObj) => {
  const alternate = get(supplementalObj, 'alternate', false);
  const title = get(supplementalObj,'title','');
  const subtitle = get(supplementalObj,'subtitle','');
  const contentType = get(supplementalObj, 'type', {});
  const colorTheme = get(supplementalObj, 'overrideThemeBackground') ? 'transparent' : get(supplementalObj, 'color_theme');
  const image = get(supplementalObj, 'type.dropdown_image.url', false);
  const [isTabletorLower, setIsTabletorLower] = useState(false);

  const handleOnChange = (event) => {
    if(isTabletorLower) {
      window.location.assign(event.target.value);
    } else {
      window.open(event.target.value, '_blank');
    }
  }

  useEffect(() => {
    setIsTabletorLower(window.matchMedia('(max-width: 1200px)').matches);
  }, []);


  const determineSupplementalType = (contentType) => {
    switch(get(contentType,'content_type','')){
      case 'dropdown':
        const label = get(contentType,'dropdown_label' ,'');
        const links = get(contentType,'dropdown_links' ,[]);
        const buttonColor = get(contentType , 'dropdown_button_color', '#000000');
        const buttonLink = get(contentType,'dropdown_button_link', '#');
        const buttonText = get(contentType, 'dropdown_button_text', '');

        return (
          <div className={`dropdown_container ${alternate ? 'alternate' : ''}`}>
            {!alternate && <label for="dropdown"> {label} </label>}
            {alternate && 
              <a className="alternateButton" target="_blank" href={buttonLink}>
                <Button
                  label={buttonText}
                  rounded
                  labelHoverColor="#FFF"
                  labelColor="#FFF"
                  hoverColor="#000"
                  bgColor={buttonColor}
                  font="normal normal normal 12px/14px CentraNo2"
                  letterSpacing="0.1em"
                  uppercase
                />
              </a>
            }
            <select name="dropdown" onChange={handleOnChange}>
              <option hidden={true}> {alternate ? label : 2024} </option> 
              {links.map((dropdown_link, index) => {
                let link = get(dropdown_link,'dropdown_link','');
                let label = get(dropdown_link,'dropdown_label' ,'') || get(dropdown_link,'drowdown_label' ,'');
                return (
                  <option key={index} value={link}> {label} </option>
                )
              })}
            </select>
          </div>
        )

      case 'button':
        let text = get(contentType,'button_text','');
        let color = get(contentType,'button_color','');
        let link = get(contentType,'button_link','');
        return (
          <a target="_blank" href={link}>
            <Button
              label={text}
              rounded
              labelHoverColor="#FFF"
              labelColor="#FFF"
              hoverColor="#000"
              bgColor={color}
              font="normal normal normal 12px/14px CentraNo2"
              letterSpacing="0.1em"
              uppercase
            />
          </a>
        )

      default:
        return '';
    }

  }
  
  return ( 
    <div className={`supplemental_container ${alternate ? 'alternate' : ''}`} style={{borderTop: `1px solid ${colorTheme}`}}>
      {alternate && image && <div className="image_container"> <img src={image} alt=''/> </div>}
      <div>
        <div className='text_container'>
          <p className={`hed ${alternate ? 'alternate' : ''}`}> {title}</p>
          <p className={`sub_hed ${alternate ? 'alternate' : ''}`}> {subtitle} </p>
        </div>
        <div className="supplemental_type">
          {determineSupplementalType(contentType)}
        </div>
      </div>
    </div>
  );
}
