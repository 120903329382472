import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OpenToggle } from '../OpenToggle/OpenToggle';
import { Image } from '../Image/Image';
import useSortable from '../../hooks/useSortable';
import { get, isNumber} from 'lodash';

export const Table = ({ listItems, interstitialUnit, interstitialFreq = 10, displayfields, showHeader, type, behavior, arrow, actions, activeIndex, iconHover }) => {
  const [active, setActive] = useState(false);
  const [columnLayout, setColumnLayout] = useState(null);
  const { items, requestSort, sortConfig } = useSortable(listItems, { key: 'rank', direction: 'ascending' });

  /**
   * Helper for formatting rank to style spec
   */
  const formatRank = (rank) => {
    if (rank >= 10 && rank < 100) {
      return `0${rank}`;
    } else if (rank < 10) {
      return `00${rank}`;
    } else {
      return rank;
    }
  }

  /**
   * Helper for generating element class names
   */
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  /**
   * Handle setting the active table row/item/record
   */
  const handleActive = (i) => {
		if (behavior === 'link') {
			window.open(items[i].url, '_blank');
		} else {
			setActive(active === i ? null : i);
		}
  }

  const keysToDisplay = displayfields.map(field => {
    return {
      name: field.name,
      weight: field.weight || 1
    }
  });

  if (items.length <= 0) return null;

	// Sets up grid spacing...
	const defineColumns = () => {
		let cols = [];

		displayfields.map(col => {
      if (col.width || col.weight !== '0') {
        cols.push(col.width ? `minmax(${col.width}px, ${col.width}px)` : `${col.weight}fr`);
      }
    });

    cols.push('minmax(36px, 36px)');

		return cols.toString().replace(/,(?!\s)/ig, ' ');
	}

  useEffect(() => {
    setColumnLayout(defineColumns());
  }, [listItems]);

  return (
    <div className={`list-items-container`}>
      {showHeader &&(
        <div className={`list-row list-row__header`} key={`heading-item-X`}>
          <div className={`container`} style={{gridTemplateColumns: columnLayout}}>
            {displayfields.map((col, i) => {
              if(typeof col === 'undefined'){
                return <div key={`list-row-col-${i}`} className={`column`} />
              }

              return (
                <button
                  key={`list-row-col-${i}`}
                  className={`column ${col.name} ${getClassNamesFor(col.name)}`}
                  type="button"
                  onClick={() => requestSort(col.name)}
                  style={{ flex: col.weight || 1 }}
                >
                  {col.label}
                </button>
              )
            })}
            {typeof type !== 'undefined' && (
              <div className={`column control`} />
            )}
          </div>
          <div className={`container__mobile`}>
            {/* <Select
              options={keysToDisplay.map((key => key.name))}
              onChange={(event) => {
                requestSort(event.target.value)
              }}
            /> */}
          </div>
        </div>
      )}
      {items.map((item, i) => (
        <Fragment key={`item-${i}`}>
          {i !== 0 && Number.isInteger(i / interstitialFreq) && interstitialUnit && (
            <div className={`list-row list-row__item ad`} key={`ad-${i / interstitialFreq}`}>
              <div className={`container ad`}>
                {interstitialUnit}
              </div>
            </div>
          )}
          <div
            className={`list-row list-row__item ${active === i ? 'active' : ''}`}
            onClick={(e) => {
              handleActive(i);
              if(get(actions, 'onRowClick')){
                actions.onRowClick(e, item, i);
              }
            }}
          >
            <div className={`container tab-${activeIndex}`} style={{gridTemplateColumns: columnLayout}}>
              {keysToDisplay.map((key, i) => {
                if(key.name === 'icon'){

                  return (
                    <div
                      key={`list-item-key-${i}`}
                      className={`column ${key.name}`}
                    >
                      <img src={item[key.name]} />
                    </div>
                  );
                }

                if(key.name === 'iconHover' && iconHover){

                  return (
                    <div
                      key={`list-item-key-${i}`}
                      className={`column ${key.name}`}
                    >
                      <img src={item[key.name]} />
                    </div>
                  );
                }

                if(key.name === 'titles'){
                  return (
                    <div
                      key={`list-item-key-${i}`}
                      className={`column ${key.name}`}
                    >
                      <div className="title__category">{item.category}</div>
                      <h2 className="title__title">{item.title}</h2> {/* this is used by franchises and its better to have h2's for SEO*/}
                      <div className="title__company" dangerouslySetInnerHTML={{__html: item.company}} />
                    </div>
                  );
                }

                if(key.name === 'image'){
                  
                  return (
                    <div
                      key={`list-item-key-${i}`}
                      className={`column ${key.name}`}
                    >
                      <img src={item[key.name]} />
                    </div>
                  );
                }

                return (
                  <div
                    key={`list-item-key-${i}`}
                    className={`column ${key.name} ${key.label} ${key.weight}`}
                    dangerouslySetInnerHTML={{__html: formatRank(item[key.name])}}
                  />
                );
              })}

              {behavior === 'expand' && (
                <div className={`column control`}>
                  <OpenToggle active={active === i} />
                </div>
              )}

              {behavior === 'link' && (
                <div className={`column control`} onClick={() => {}}>
                  {arrow ? (
                    <Image
                      // image={`https://assets.fastcompany.com/image/upload/v1626644494/fcweb/icon_2x.webp`}
                      image={arrow}
                      width="30px"
                      height="30px"
                    />
                  ) :
                  (
                    <div>→</div>
                  )
                  }
                </div>
              )}
            </div>
            {behavior === 'expand' && active === i && (
              <div className={`column description`}>
                <div className={`copy`} dangerouslySetInnerHTML={{ __html: item.description }} />
                {item.url &&
                  <a href={item.url} className={`button`} target="_blank" rel="noopener noreferrer">Read More</a>
                }
              </div>
            )}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

Table.propTypes = {
  listItems: PropTypes.array,
  showHeader: PropTypes.bool,
  displayfields: PropTypes.array,
  interstitialUnit: PropTypes.any,
  type: PropTypes.string,
  behavior: PropTypes.string,
  arrow: PropTypes.string,
  actions: PropTypes.shape({
    onRowClick: PropTypes.func
  })
}
